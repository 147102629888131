import axios from "axios";
import React, { useEffect, useState } from "react";
import { FacebookChat } from "../../components/facebook-chat";
import Container from "../../components/container";
import { connect } from "react-redux";

import * as css from "./style.module.scss";

type SelectedAgentType = {
  phone: string;
  business_address: string;
  business_phone: string;
  email: string;
  messenger_id: string;
};

const NoResultsPage = ({ agentMetadata, baseUrl }) => {
  const [selectedAgent, setSelectedAgent] = useState({} as SelectedAgentType);

  useEffect(() => {
    if (agentMetadata.id) {
      (async () => {
        const response = await axios.get(
          `${baseUrl}/v1/platforms/${agentMetadata.id}`,
          {
            headers: {
              Authorization: `Bearer ${process.env.GATSBY_PUBLIC_TOKEN}`,
            },
          }
        );

        window.sessionStorage.setItem(
          "agent-data",
          JSON.stringify(response.data)
        );

        setSelectedAgent({ ...response.data });
      })();
    }
  }, [agentMetadata]);

  return (
    <>
      <Container className={css.pageWrapper}>
        <Container className={css.squareTop} />
        <Container className={css.squareBottom} />
        <Container className={css.centeredContent}>
          <a className={css.imgWrapper} href="/">
            <img src="/maq_logo_black.svg" alt="" />
          </a>
          <Container className={css.textWrapper}>
            <h1>
              Sorry, no results were available for the type of search you
              entered.
            </h1>
            <p>Please contact us directly. We are happy to help!</p>
          </Container>
          <Container className={css.infoWrapper}>
            {selectedAgent.messenger_id && (
              <button type="button">
                <Container className={css.iconWrapper}>
                  <img src="/messenger.png" alt="Messenger Icon" />
                </Container>
                <Container>
                  <span>Live Chat</span>
                  <span>
                    Chat online with an agent by clicking on the button in the
                    right corner
                  </span>
                </Container>
              </button>
            )}
            {selectedAgent.phone && (
              <button type="button">
                <Container className={css.iconWrapper}>
                  <img src="/message.png" alt="Messenger Icon" />
                </Container>
                <Container>
                  <span>Text an Agent</span>
                  <span>Text us on {selectedAgent?.phone}</span>
                </Container>
              </button>
            )}
            {selectedAgent.business_phone && (
              <button type="button">
                <Container className={css.iconWrapper}>
                  <img src="/phone.png" alt="Messenger Icon" />
                </Container>
                <Container>
                  <span>Call an Agent</span>
                  <span>Call us on {selectedAgent?.business_phone}</span>
                </Container>
              </button>
            )}
            {selectedAgent.email && (
              <button type="button">
                <Container className={css.iconWrapper}>
                  <img src="/mail.png" alt="Messenger Icon" />
                </Container>
                <Container>
                  <span>Email Us</span>
                  <span>{selectedAgent?.email}</span>
                </Container>
              </button>
            )}
            {selectedAgent.business_address && (
              <button type="button">
                <Container className={css.iconWrapper}>
                  <img src="/contacts.png" alt="Messenger Icon" />
                </Container>
                <Container>
                  <span>Mailing Address</span>
                  <span>Send to {selectedAgent?.business_address}</span>
                </Container>
              </button>
            )}
          </Container>
        </Container>
      </Container>
      {selectedAgent.messenger_id && (
        <FacebookChat
          pageId={selectedAgent?.messenger_id}
          themeColor="#053555"
        />
      )}
    </>
  );
};

const mapStateToProps = ({ ...rest }) => {
  return {
    ...rest,
  };
};

export default connect(mapStateToProps)(NoResultsPage);
